.homepage-points
  .homepage-points__contents
  .homepage-points__titles
  .homepage-points__title
  span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.homepage-points.active
  .homepage-points__contents
  .homepage-points__titles
  .homepage-points__title
  span.letter {
  @apply opacity-100 transform scale-100;
}

.homepage-points.already-onscreen
  .homepage-points__contents
  .homepage-points__titles
  .homepage-points__title
  span.letter {
  @apply transition-none duration-none;
}

.homepage-points
  .homepage-points__contents
  .homepage-points__titles
  .homepage-points__subtitle {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-points.active
  .homepage-points__contents
  .homepage-points__titles
  .homepage-points__subtitle {
  @apply opacity-100 transform scale-100;
}

.homepage-points.already-onscreen
  .homepage-points__contents
  .homepage-points__titles
  .homepage-points__subtitle {
  @apply transition-none duration-none;
}

.homepage-points .homepage-points__contents .homepage-points__points {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-points.active .homepage-points__contents .homepage-points__points {
  @apply opacity-100 transform scale-100;
}

.homepage-points.already-onscreen
  .homepage-points__contents
  .homepage-points__points {
  @apply transition-none duration-none;
}

.homepage-points .homepage-points__contents .homepage-points__link-container {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-points.active
  .homepage-points__contents
  .homepage-points__link-container {
  @apply opacity-100 transform scale-100;
}

.homepage-points.already-onscreen
  .homepage-points__contents
  .homepage-points__link-container {
  @apply transition-none duration-none;
}
