.homepage-hero {
  height: calc(100vh - theme("spacing.18"));
}

@screen desktop {
  .homepage-hero {
    height: theme("spacing.180");
  }
}

.homepage-hero
  .homepage-hero__contents
  .homepage-hero__softwares
  .homepage-hero__software
  .homepage-hero__title
  span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.homepage-hero.active
  .homepage-hero__contents
  .homepage-hero__softwares
  .homepage-hero__software
  .homepage-hero__title
  span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.homepage-hero.already-onscreen
  .homepage-hero__contents
  .homepage-hero__softwares
  .homepage-hero__software
  .homepage-hero__title
  span.letter {
  @apply transition-none duration-none;
}
