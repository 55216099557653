.homepage-intro .homepage-intro__contents .homepage-intro__title span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.homepage-intro.active
  .homepage-intro__contents
  .homepage-intro__title
  span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.homepage-intro.already-onscreen
  .homepage-intro__contents
  .homepage-intro__title
  span.letter {
  @apply transition-none duration-none;
}

.homepage-intro .homepage-intro__contents .homepage-intro__text {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-intro.active .homepage-intro__contents .homepage-intro__text {
  @apply opacity-100 transform scale-100;
}

.homepage-intro.already-onscreen
  .homepage-intro__contents
  .homepage-intro__text {
  @apply transition-none duration-none;
}
