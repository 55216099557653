header.site-header {
  @apply sticky z-50 top-0 w-full;
}

/* DESKTOP STYLES */

header.site-header nav.navbar .navbar-desktop {
  @apply desktop:block hidden w-full h-18;
}

header.site-header nav.navbar .navbar-desktop .navbar-desktop__bar {
  @apply flex flex-row items-center justify-center w-full h-18 bg-white shadow-lg transition duration-300 ease-in-out;
}

header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container {
  @apply flex flex-row items-center justify-between tablet:px-4 px-8;
}

header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__left,
header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__right {
  @apply flex flex-row items-center justify-start space-x-8;
}

header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__left
  .navbar-desktop__item
  .navbar-desktop__link.navbar-desktop__link--text,
header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__right
  .navbar-desktop__item
  .navbar-desktop__link.navbar-desktop__link--text {
  @apply text-almost-black text-button font-bold uppercase transition duration-300 ease-in-out;
}

header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__left
  .navbar-desktop__item
  .navbar-desktop__link.navbar-desktop__link--products {
  @apply text-almost-black text-button font-bold uppercase transition duration-300 ease-in-out;
}

header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__left
  .navbar-desktop__icon {
  @apply text-almost-black transition duration-300 ease-in-out;
}

header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__right
  .navbar-desktop__item
  .navbar-desktop__link.navbar-desktop__link--button {
  @apply px-6 py-2 hover:text-electric-blue text-white font-bold bg-electric-blue hover:bg-white focus:bg-white border border-electric-blue cursor-pointer uppercase transition duration-300 ease-in-out;
}

header.site-header nav.navbar .navbar-desktop .navbar-desktop__menu {
  @apply w-full bg-transparent opacity-0 pointer-events-none transition duration-300 ease-in-out;
}

header.site-header nav.navbar.active .navbar-desktop .navbar-desktop__menu {
  @apply opacity-100 pointer-events-auto;
}

header.site-header
  nav.navbar
  .navbar-desktop
  .navbar-desktop__menu
  .navbar-desktop__menu-contents {
  @apply container px-0 bg-off-white shadow-lg;
}

header.site-header
  nav.navbar.active
  .navbar-desktop
  .navbar-desktop__bar
  .navbar-desktop__bar-container
  .navbar-desktop__left
  .navbar-desktop__item
  .navbar-desktop__link.navbar-desktop__link--products {
  @apply text-electric-blue;
}

/* MOBILE STYLES */

header.site-header {
  @apply z-header sticky top-0 w-full;
}

header.site-header nav.navbar .navbar-mobile {
  @apply block desktop:hidden w-full h-18;
}

header.site-header nav.navbar .navbar-mobile .navbar-mobile__bar {
  @apply flex flex-row items-center justify-center w-full h-18 bg-white shadow-lg transition duration-300 ease-in-out;
}

header.site-header
  nav.navbar
  .navbar-mobile
  .navbar-mobile__bar
  .navbar-mobile__top-request {
  @apply block;
}

header.site-header
  nav.navbar
  .navbar-mobile
  .navbar-mobile__bar
  .navbar-mobile__bar-container {
  @apply flex flex-row items-center justify-between;
}

header.site-header
  nav.navbar
  .navbar-mobile
  .navbar-mobile__bar
  .navbar-mobile__bar-container
  .navbar-mobile__right {
  @apply flex flex-row items-center justify-between space-x-4;
}

header.site-header nav.navbar .navbar-mobile .navbar-mobile__menu {
  height: calc(100vh - theme("spacing.18"));
  @apply opacity-0 pointer-events-none transition duration-300 ease-in-out;
}

/* MOBILE STYLES ACTIVE */

header.site-header nav.navbar.active .navbar-mobile {
  @apply bg-off-white;
}

header.site-header nav.navbar.active .navbar-mobile__bar {
  @apply bg-off-white;
}

header.site-header nav.navbar.active .navbar-mobile .navbar-mobile__menu {
  @apply bg-off-white opacity-100 pointer-events-auto;
}

header.site-header
  nav.navbar.active
  .navbar-mobile
  .navbar-mobile__bar
  .navbar-mobile__top-request {
  @apply hidden;
}
