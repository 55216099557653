/* knowledge SWIPER */

.software-knowledge
  .software-knowledge__contents
  .software-knowledge__list.software-knowledge__list--mobile
  .software-knowledge__list-swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.software-knowledge
  .software-knowledge__contents
  .software-knowledge__list.software-knowledge__list--mobile
  .software-knowledge__list-swiper
  .software-knowledge__list-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.software-knowledge
  .software-knowledge__contents
  .software-knowledge__list.software-knowledge__list--mobile
  .software-knowledge__list-swiper
  .software-knowledge__list-wrapper
  .software-knowledge__list-slide {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  position: relative;
  transition-property: transform;
}
