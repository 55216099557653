.button {
  @apply px-6 py-2 font-bold cursor-pointer uppercase;
}

.button.button--small {
  @apply text-small-button;
}

.button.button--normal {
  @apply text-button;
}

.button.button--large {
  @apply text-large-button;
}

.button.button--primary {
  @apply text-white hover:bg-almost-black focus:bg-almost-black bg-electric-blue;
}

.button.button--secondary {
  @apply hover:text-almost-black focus:text-almost-black text-electric-blue hover:bg-transparent focus:bg-transparent bg-transparent border hover:border-almost-black focus:border-almost-black border-electric-blue;
}

.button.button--tertiary {
  @apply inline px-0 py-0 hover:text-almost-black focus:text-almost-black text-electric-blue hover:bg-transparent focus:bg-transparent bg-transparent;
}

.button.button--white {
  @apply text-almost-black hover:text-white focus:text-white hover:bg-almost-black focus:bg-almost-black bg-white;
}

.button.button--rewards {
  @apply text-rewards-red hover:text-white focus:text-white hover:bg-rewards-red focus:bg-rewards-red bg-transparent border hover:border-rewards-red focus:border-rewards-red border-rewards-red;
}

.button.button--mar {
  @apply text-mar-green hover:text-white focus:text-white hover:bg-mar-green focus:bg-mar-green bg-transparent border hover:border-mar-green focus:border-mar-green border-mar-green;
}
