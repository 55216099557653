.homepage-studies .homepage-studies__contents .homepage-studies__titles {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-studies.active .homepage-studies__contents .homepage-studies__titles {
  @apply opacity-100 transform scale-100;
}

.homepage-studies.already-onscreen
  .homepage-studies__contents
  .homepage-studies__titles {
  @apply transition-none duration-none;
}

.homepage-studies .homepage-studies__buttons .homepage-studies__button {
  @apply block flex-grow px-4 py-2 w-max font-bold outline-none cursor-pointer;
}

.homepage-studies
  .homepage-studies__buttons
  .homepage-studies__button.homepage-studies__button--rewards {
  @apply text-rewards-red hover:text-white focus:text-white hover:bg-rewards-red focus:bg-rewards-red bg-transparent border hover:border-rewards-red focus:border-rewards-red border-rewards-red;
}

.homepage-studies
  .homepage-studies__buttons
  .homepage-studies__button.homepage-studies__button--active.homepage-studies__button--rewards {
  @apply text-white hover:text-white focus:text-white hover:bg-rewards-red focus:bg-rewards-red bg-rewards-red border hover:border-rewards-red focus:border-rewards-red border-rewards-red;
}

.homepage-studies
  .homepage-studies__buttons
  .homepage-studies__button.homepage-studies__button--mar {
  @apply text-mar-green hover:text-white focus:text-white hover:bg-mar-green focus:bg-mar-green bg-transparent border hover:border-mar-green focus:border-mar-green border-mar-green;
}

.homepage-studies
  .homepage-studies__buttons
  .homepage-studies__button.homepage-studies__button--active.homepage-studies__button--mar {
  @apply text-white hover:text-white focus:text-white hover:bg-mar-green focus:bg-mar-green bg-mar-green border hover:border-mar-green focus:border-mar-green border-mar-green;
}

.homepage-studies .homepage-studies__contents .homepage-studies__studies {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-studies.active
  .homepage-studies__contents
  .homepage-studies__studies {
  @apply opacity-100 transform scale-100;
}
.homepage-studies.already-onscreen
  .homepage-studies__contents
  .homepage-studies__studies {
  @apply transition-none duration-none;
}

.homepage-studies .homepage-studies__contents .homepage-studies__link {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-studies.active .homepage-studies__contents .homepage-studies__link {
  @apply opacity-100 transform scale-100;
}

.homepage-studies.active .homepage-studies__contents .homepage-studies__link {
  @apply transition-none duration-none;
}

/* STUDIES SWIPER */

.homepage-studies
  .homepage-studies__contents
  .homepage-studies__studies.homepage-studies__studies--mobile
  .homepage-studies__swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.homepage-studies
  .homepage-studies__contents
  .homepage-studies__studies.homepage-studies__studies--mobile
  .homepage-studies__swiper
  .homepage-studies__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.homepage-studies
  .homepage-studies__contents
  .homepage-studies__studies.homepage-studies__studies--mobile
  .homepage-studies__swiper
  .homepage-studies__wrapper
  .homepage-studies__slide {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  position: relative;
  transition-property: transform;
}
