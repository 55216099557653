.homepage-demo .footer-demo__contents .footer-demo__title span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.homepage-demo.active .footer-demo__contents .footer-demo__title span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.homepage-demo .footer-demo__contents .footer-demo__text {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-demo.active .footer-demo__contents .footer-demo__text {
  @apply opacity-100 transform scale-100;
}

.homepage-demo .footer-demo__contents .footer-demo__form {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-demo.active .footer-demo__contents .footer-demo__form {
  @apply opacity-100 transform scale-100;
}

.footer-section__contents .footer-subscribe__form-submit {
  @apply hover:text-electric-blue hover:bg-white;
}
