.about-team
  .about-team__contents
  .about-team__list.about-team__list--mobile
  .about-team__list-swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.about-team
  .about-team__contents
  .about-team__list.about-team__list--mobile
  .about-team__list-swiper
  .about-team__list-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.about-team
  .about-team__contents
  .about-team__list.about-team__list--mobile
  .about-team__list-swiper
  .about-team__list-wrapper
  .about-team__list-slide {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  position: relative;
  transition-property: transform;
}
