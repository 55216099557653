.homepage-about .homepage-about__contents .homepage-about__title span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.homepage-about.active
  .homepage-about__contents
  .homepage-about__title
  span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.homepage-about.already-onscreen
  .homepage-about__contents
  .homepage-about__title
  span.letter {
  @apply transition-none duration-none;
}

.homepage-about .homepage-about__contents .homepage-about__info,
.homepage-about
  .homepage-about__contents
  .homepage-about__team
  .homepage-about__team-member {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-about.active .homepage-about__contents .homepage-about__info,
.homepage-about.active
  .homepage-about__contents
  .homepage-about__team
  .homepage-about__team-member {
  @apply opacity-100 transform scale-100;
}

.homepage-about.already-onscreen
  .homepage-about__contents
  .homepage-about__info,
.homepage-about.already-onscreen
  .homepage-about__contents
  .homepage-about__team
  .homepage-about__team-member {
  @apply transition-none duration-none;
}
