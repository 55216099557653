@import "./partials/fonts";
@import "./partials/tailwind";
@import "./partials/gutenberg";
@import "./partials/buttons";

/* Importing Sections styles */
@import "./sections/header.css";

@import "./sections/homepage/software.css";
@import "./sections/homepage/hero.css";
@import "./sections/homepage/about.css";
@import "./sections/homepage/studies.css";
@import "./sections/homepage/knowledge.css";
@import "./sections/homepage/intro.css";
@import "./sections/homepage/points.css";
@import "./sections/homepage/faqs.css";

@import "./sections/software/studies.css";
@import "./sections/software/knowledge.css";

@import "./sections/case-studies/other-posts.css";
@import "./sections/case-studies/post-content.css";

@import "./sections/knowledge-hub/other-posts.css";
@import "./sections/knowledge-hub/post-content.css";

@import "./sections/pages/post-content.css";

@import "./sections/about/team.css";

@import "./sections/footer.css";

html {
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  scroll-padding-top: theme("spacing.18");
}

body {
  @apply text-almost-black;
}

p {
  @apply font-body text-base font-normal;
}

h1 {
  @apply font-heading tablet:text-desktop-h1 text-mobile-h1 font-normal;
}

h2 {
  @apply font-heading tablet:text-desktop-h2 text-mobile-h2 font-normal;
}

h3 {
  @apply font-heading tablet:text-desktop-h3 text-mobile-h3 font-normal;
}

h4 {
  @apply font-heading tablet:text-desktop-h4 text-mobile-h4 font-normal;
}

h5 {
  @apply font-heading tablet:text-desktop-h5 text-mobile-h5 font-normal;
}

h6 {
  @apply font-heading tablet:text-desktop-h6 text-mobile-h6 font-normal;
}

@responsive {
  .writing-mode-vertical {
    writing-mode: vertical-lr;
  }

  .writing-mode-normal {
    writing-mode: initial;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
