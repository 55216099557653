.knowledge-hub-post .knowledge-hub-post__contents {
  @apply space-y-8;
}

.knowledge-hub-post .knowledge-hub-post__contents h2 {
  @apply text-blog-h2;
}

.knowledge-hub-post .knowledge-hub-post__contents h3 {
  @apply text-blog-h3;
}

.knowledge-hub-post .knowledge-hub-post__contents p,
.knowledge-hub-post .knowledge-hub-post__contents ul {
  @apply text-big;
}

.knowledge-hub-post .knowledge-hub-post__contents p.has-small-font-size,
.knowledge-hub-post .knowledge-hub-post__contents ul.has-small-font-size {
  @apply text-base;
}
.knowledge-hub-post .knowledge-hub-post__contents p.has-normal-font-size,
.knowledge-hub-post .knowledge-hub-post__contents ul.has-normal-font-size {
  @apply text-lg;
}
.knowledge-hub-post .knowledge-hub-post__contents p.has-medium-font-size,
.knowledge-hub-post .knowledge-hub-post__contents ul.has-medium-font-size {
  @apply text-xl;
}
.knowledge-hub-post .knowledge-hub-post__contents p.has-large-font-size,
.knowledge-hub-post .knowledge-hub-post__contents ul.has-large-font-size {
  @apply text-2xl;
}
.knowledge-hub-post .knowledge-hub-post__contents p.has-huge-font-size,
.knowledge-hub-post .knowledge-hub-post__contents ul.has-huge-font-size {
  @apply text-3xl;
}

.knowledge-hub-post .knowledge-hub-post__contents ul {
  @apply list-inside list-disc text-big;
}

.knowledge-hub-post .knowledge-hub-post__contents ol {
  @apply list-inside list-decimal text-big;
}
