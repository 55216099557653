.knowledge-hub-other
  .knowledge-hub-other__contents
  .knowledge-hub-other__title
  span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.knowledge-hub-other.active
  .knowledge-hub-other__contents
  .knowledge-hub-other__title
  span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.knowledge-hub-other
  .knowledge-hub-other__contents
  .knowledge-hub-other__posts {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.knowledge-hub-other.active
  .knowledge-hub-other__contents
  .knowledge-hub-other__posts {
  @apply opacity-100 transform scale-100;
}
.knowledge-hub-other.already-onscreen
  .knowledge-hub-other__contents
  .knowledge-hub-other__posts {
  @apply transition-none duration-none;
}

/* STUDIES SWIPER */

.knowledge-hub-other
  .knowledge-hub-other__contents
  .knowledge-hub-other__posts.knowledge-hub-other__posts--mobile
  .knowledge-hub-other__posts-swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  list-style: none;
  padding: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.knowledge-hub-other
  .knowledge-hub-other__contents
  .knowledge-hub-other__posts.knowledge-hub-other__posts--mobile
  .knowledge-hub-other__posts-swiper
  .knowledge-hub-other__posts-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.knowledge-hub-other
  .knowledge-hub-other__contents
  .knowledge-hub-other__posts.knowledge-hub-other__posts--mobile
  .knowledge-hub-other__posts-swiper
  .knowledge-hub-other__posts-wrapper
  .knowledge-hub-other__posts-slide {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  position: relative;
  transition-property: transform;
}
