.tw-gutenberg {
  @apply overflow-hidden;
}

.tw-gutenberg p {
  @apply mb-4 text-base;
}

.tw-gutenberg h1 {
  @apply mb-4 text-4xl;
}

.tw-gutenberg h2 {
  @apply mb-4 text-3xl;
}

.tw-gutenberg h3 {
  @apply mb-4 text-2xl;
}

.tw-gutenberg h4 {
  @apply mb-4 text-xl;
}

.tw-gutenberg h5 {
  @apply mb-4 text-lg;
}

.tw-gutenberg h6 {
  @apply mb-4 text-base;
}

.tw-gutenberg ul {
  @apply pl-8 list-disc;
}

.tw-gutenberg ol {
  @apply pl-8 list-decimal;
}

.tw-gutenberg blockquote {
  @apply ml-4 p-4 border-l-2 border-solid border-black;
}

.tw-gutenberg .wp-block-image {
  @apply m-0;
}

.tw-gutenberg .wp-block-image figure {
  @apply m-0;
}

.tw-gutenberg .wp-block-image figcaption {
  @apply m-0 mb-4;
}

.tw-gutenberg .wp-block-image figure.aligncenter {
  @apply mx-auto;
}

.tw-gutenberg .wp-block-image figure.alignleft img {
  @apply mb-4 mr-4;
}

.tw-gutenberg .wp-block-image figure.alignleft figcaption {
  @apply mb-4 mr-4;
}

.tw-gutenberg .wp-block-image figure.aligncenter img {
  @apply mb-4;
}

.tw-gutenberg .wp-block-image figure.aligncenter figcaption {
  @apply mb-4;
}

.tw-gutenberg .wp-block-image figure.alignright img {
  @apply mb-4 ml-4;
}

.tw-gutenberg .wp-block-image figure.alignright figcaption {
  @apply mb-4 ml-4;
}

.tw-gutenberg .wp-block-video {
  @apply m-0 mx-auto;
}

.tw-gutenberg .wp-block-video video {
  @apply mb-4;
}

.tw-gutenberg .wp-block-video figcaption {
  @apply m-0 mb-4;
}
