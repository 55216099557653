.homepage-software
  .homepage-software__contents
  .homepage-software__title
  span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.homepage-software.active
  .homepage-software__contents
  .homepage-software__title
  span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.homepage-software.already-onscreen
  .homepage-software__contents
  .homepage-software__title
  span.letter {
  @apply transition-none duration-none;
}

.homepage-software .homepage-software__contents .homepage-software__tables {
  @apply opacity-0 transform scale-90 transition-all duration-2000 ease-in-out;
}

.homepage-software.active
  .homepage-software__contents
  .homepage-software__tables {
  @apply opacity-100 transform scale-100;
}

.homepage-software.already-onscreen
  .homepage-software__contents
  .homepage-software__tables {
  @apply transition-none duration-none;
}

/* Type  */

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types {
  @apply grid gap-x-4 desktop:gap-y-0 gap-y-4 grid-cols-12;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type {
  @apply col-span-12 tablet:col-span-6;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type.homepage-software__type--multi {
  @apply col-span-12 tablet:col-span-6;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type.homepage-software__type--single {
  @apply col-span-12 tablet:col-span-12;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type
  .homepage-software__type-top {
  @apply flex flex-row items-center justify-between desktop:justify-start w-full outline-none focus:outline-none;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type
  .homepage-software__type-bottom {
  @apply desktop:grid hidden gap-y-2 grid-cols-12;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type.homepage-software__type
  .homepage-software__type-bottom
  .homepage-software__type-point {
  @apply flex flex-row items-baseline justify-start space-x-4;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type.homepage-software__type--single
  .homepage-software__type-bottom
  .homepage-software__type-point {
  @apply col-span-12 desktop:col-span-6;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type.homepage-software__type--multi
  .homepage-software__type-bottom
  .homepage-software__type-point {
  @apply col-span-12;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type.homepage-software__type--active
  .homepage-software__type-top {
  @apply desktop:mb-0 mb-4;
}

.homepage-software
  .homepage-software__contents
  .homepage-software__item
  .homepage-software__types
  .homepage-software__type.homepage-software__type--active
  .homepage-software__type-bottom {
  @apply grid;
}
