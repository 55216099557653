.homepage-faqs {
  background-size: 200% 200%;
  background-position: 0 100%;
  background-image: linear-gradient(
    to bottom,
    theme("colors.cool-mint") 50%,
    theme("colors.off-white") 50%
  );
  @apply transition-all duration-2000 ease-in-out;
}

.homepage-faqs
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__title
  span.letter {
  @apply inline-block opacity-0 transform -translate-y-8 translate-x-8 transition-all duration-2000 ease-in-out;
}

.homepage-faqs.active
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__title
  span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.homepage-faqs.already-onscreen
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__title
  span.letter {
  @apply transition-none duration-none;
}

.homepage-faqs
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__subtitle {
  @apply opacity-0 transform -translate-y-4 transition-all duration-2000 ease-in-out;
}

.homepage-faqs
  .homepage-faqs__contents
  .homepage-faqs__faqs
  .homepage-faqs__faq {
  @apply opacity-0 transform -translate-y-4 transition-all duration-2000 ease-in-out;
}

.homepage-faqs .homepage-faqs__contents .homepage-faqs__link-container {
  @apply opacity-0 transform -translate-y-4 transition-all duration-2000 ease-in-out;
}

.homepage-faqs.active {
  background-position: 0 0;
}

.homepage-faqs.active
  .homepage-faqs__contents
  .homepage-faqs__faqs
  .homepage-faqs__faq {
  @apply opacity-100 transform translate-y-0;
}

.homepage-faqs.active
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__title
  span.letter {
  @apply opacity-100 transform translate-x-0 translate-y-0;
}

.homepage-faqs.active
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__subtitle {
  @apply opacity-100 transform translate-y-0;
}

.homepage-faqs.active .homepage-faqs__contents .homepage-faqs__link-container {
  @apply opacity-100 transform translate-y-0;
}

.homepage-faqs.already-onscreen
  .homepage-faqs__contents
  .homepage-faqs__faqs
  .homepage-faqs__faq {
  @apply transition-none duration-none;
}

.homepage-faqs.already-onscreen
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__title
  span.letter {
  @apply transition-none duration-none;
}

.homepage-faqs.already-onscreen
  .homepage-faqs__contents
  .homepage-faqs__titles
  .homepage-faqs__subtitle {
  @apply transition-none duration-none;
}

.homepage-faqs.already-onscreen .homepage-faqs__contents .homepage-faqs__link {
  @apply transition-none duration-none;
}
