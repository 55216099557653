.case-studies-post .case-studies-post__contents {
  @apply space-y-8;
}

.case-studies-post .case-studies-post__contents p,
.case-studies-post .case-studies-post__contents ul {
  @apply text-lg;
}

.case-studies-post .case-studies-post__contents p.has-small-font-size,
.case-studies-post .case-studies-post__contents ul.has-small-font-size {
  @apply text-base;
}
.case-studies-post .case-studies-post__contents p.has-normal-font-size,
.case-studies-post .case-studies-post__contents ul.has-normal-font-size {
  @apply text-lg;
}
.case-studies-post .case-studies-post__contents p.has-medium-font-size,
.case-studies-post .case-studies-post__contents ul.has-medium-font-size {
  @apply text-xl;
}
.case-studies-post .case-studies-post__contents p.has-large-font-size,
.case-studies-post .case-studies-post__contents ul.has-large-font-size {
  @apply text-2xl;
}
.case-studies-post .case-studies-post__contents p.has-huge-font-size,
.case-studies-post .case-studies-post__contents ul.has-huge-font-size {
  @apply text-3xl;
}

.case-studies-post .case-studies-post__contents ul {
  @apply list-inside list-disc;
}

.case-studies-post .case-studies-post__contents ol {
  @apply list-inside list-decimal;
}
